.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  height:inherit;
  padding: 0 3em;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 5em;
  width: auto;
}

.navigation {

  gap: 2vw;
}

.nav-button {

  cursor: pointer;
}


@media (max-width: 500px) {
  .hero-content h1{
    font-size: 4vh;
  }
}