
.footer-content {
  
  width: 97%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  background-image: url('../../Asset/footer-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
 