.event-card-left {

    background-image: url('../../../Asset/Events/EventCard.svg');

    /* background-position: center; */
    background-repeat: no-repeat;
    background-size: cover;
    
   
}


@media (max-width: 768px) {
    .event-card-left {
        background-image: url('../../../Asset/Events/EventCard-mobile.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
    }

    
}

.card1 {
    background-image: url('../../../Asset/Events/event-card-badminton.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}



.card2 {
    background-image: url('../../../Asset/Events/event-card-running.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}





@media (max-width: 768px) {

    .card1 {
        background-image: url('../../../Asset/Events/event-card-badminton-mobile.svg');
        /* background-position: center;
        background-size: cover; */
        background-repeat: no-repeat;
    }

    .card2 {
        background-image: url('../../../Asset/Events/EventCard-mobile.svg');
        /* background-position: center; */
        /* background-size: cover; */
        background-repeat: no-repeat;
    }

    
}



/* For Scroll bar here*/

#scroll-container {
  scrollbar-color: auto !important;
}

#scroll-container::-webkit-scrollbar {
  width: 4px;
}

#scroll-container::-webkit-scrollbar-track {
  background: rgba(217, 217, 217, 0.2);

  border-radius: 20px;
}

#scroll-container::-webkit-scrollbar-thumb {
  background-color: var(--button-green);

  border-radius: 5px;
}





/* For checkbox */

 .custom-checkbox {
    display: none;
  }
  
  /* Style for the label, to make it look like a checkbox */
 .checkbox-container label {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background-color: var(--white);
    border: 2px solid var(--dark-blue);
    cursor: pointer;
    position: relative;
  }
  
  /* When the checkbox is checked, style the corresponding label */
.custom-checkbox:checked + label {
    background-color: var(--light-purple-checkbox-background); /* Dark purple background when selected */
    color: var(--dark-blue); /* White check mark */
  }
  
  /* Create the check mark inside the checkbox */
 .checkbox-container label:after {
    content: '✔';
    font-weight: 1000;
    font-size: 10px;
    color: var(--dark-blue);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
  
  /* Show the check mark when the checkbox is checked */
 .custom-checkbox:checked + label:after {
    display: block;
    color: var(--dark-blue); /* Check mark becomes white on selection */
  }
  
   /* For moblie device */
   @media (max-width: 480px) {
 .checkbox-container label {
      width: 14px;
      height: 14px;
      border: 2px solid var(--dark-blue);
    }
  }