.pattern-container {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.05) .1px, transparent 1px), linear-gradient(rgba(255, 255, 255, 0.05) .1px, transparent 1px);
    background-size: 10px 10px;
    background-position: center;
    position: relative;
  }

  .homepage-lineargradient-bg-color{
    background: linear-gradient(180deg, var(--linear-gradient-light-purple) 0%, var(--linear-gradient-dark-purple) 100%);
  }

  @media (max-width: 768px) {
    .homepage-lineargradient-bg-color {
      background: linear-gradient(180deg, var(--linear-gradient-light-purple) 0%, var(--linear-gradient-dark-purple) 100%);
    }
  }


  /* For Scroll bar here*/
/* 
#scroll-container {
  scrollbar-color: auto !important;
}

#scroll-container::-webkit-scrollbar {
  width: 4px;
}

#scroll-container::-webkit-scrollbar-track {
  background: rgba(217, 217, 217, 0.2);

  border-radius: 20px;
}

#scroll-container::-webkit-scrollbar-thumb {
  background-color: var(--button-green);

  border-radius: 5px;
} */
