

.card-container{
    background-image: url('../../../Asset/stayInTouch-background.svg');
    background-size: cover;
    background-position: center;
}

.carousel-football-image{
    
    background-image: url('../../../Asset/carousel-football-image.svg');
    background-size: cover;
    background-position: center;
}