.hero {
    width: 100%;
    height: 100vh;
    background: 
    linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0)),
      url('../../../Asset/hero-image.png'); 
    
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding-left: 3em;
  }
  
  .hero-content h1 {
    color: rgba(255, 255, 255, 0.6);
    
    text-align: center;
    padding: 0;
    margin: 0;
  }

  @media (max-width: 500px) {
    .hero-content h1{
      font-size: 4vh;
    
    }

    .hero img{
      display:none;
    }

    .hero{
      align-items: center;
      padding-left: 0;
    }
  }