/* For Scroll bar here*/

#scroll-container {
    scrollbar-color: auto !important;
  }
  
  #scroll-container::-webkit-scrollbar {
    width: 4px;
  }
  
  #scroll-container::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.2);
  
    border-radius: 20px;
  }
  
  #scroll-container::-webkit-scrollbar-thumb {
    background-color: var(--button-green);
  
    border-radius: 5px;
  }
  