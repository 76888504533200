/* .customerOptions-card{
    width: 24vw;
    height: 21vw;
} */

.find-play {
    background-size: contain;   
  }
.find-community {
    background-size: contain; 
  }
.find-competition {
    background-size: contain;    
  }

  
@media (max-width: 500px) {
  .customerOptions-upper-container{
     flex-direction: column;
  }
}