@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

/* src/index.css */
@font-face {
    font-family: 'ClashGrotesk';
    src: url('.././Fonts/ClashGrotesk.ttf') format('truetype');
  }
  

:root{
    --linear-gradient-light-purple: #905EEF;
    --linear-gradient-dark-purple: #4818A2;
    --base-color: rgba(81,45,154,100%);
    --homeAboutUs-purple-button: #8C59EDA1;
    --medium-purple: #512D9A;
    --purple-blue: #4D2A90;
    --dark-blue: #2D1066;
    --filter-button-text-purple: #583799;
    --filter-button-border-purple: #8F54FF;
    --light-purple-membership-package-card: #8667C0;
    --filter-button-background-purple: #ECE3FF;
    --very-light-purple:#EFE6FF;
    --light-purple-checkbox-background: #c9c3d4;
    --dull-white-homepage-nav: #FFFFFF33;
    --dull-white-otherpage-nav:#FFFFFF80;
    --dull-white-followUs-button-border: #FFFFFF4D;
    --eventListing-gray-text: #616161;
    --eventListing-filterbtn-heading-gray: #6B6B6B;
    --eventListing-filterbtn-bg-white: #FFFFFB;
    --eventListing-filterbtn-border-faded-purple:#5837994A;
    --deep-plum-disabled-button: #160833;
    --membership-checkout-modal-gray: #6D6D6D;
    

    --white: rgb(255,255,255);
    --dull-white: #DDDDDD;
    --light-white: #E0E0E0;
    --background-light-gray: #F0F0F0;
    --dark-gray-text-color: #6B6B6B;
    --button-disable-light-gray: #BABABA;
    --button-disable-dark-gray: #626262;
    
    
    --light-yellow: #FEFFED;
    --very-light-yellow: #FBFCF7;

    --button-green: #C9FF9A;
    --green-button-text-black: #444444;
    --button-hover-green: #8fe066;
    --netGala-green-disabled-btn: #65804d;
    --netGala-green-disabled-btn-txt: #38382D;
    
    --light-gray: #F1F1F1;
    --input-border-gray: #DDDDDD;
    --reddish-brown:#7c5c5c;
    --gray-brown: #707070;
    --gray-black: #464646;
    --home-about-us-ptn-button-grey: #50520A;
    --medium-black: #343434;
    --greenish-black: #293F03;
    
}