
.home-about-us {
  padding: 3em 3em 3em 3em;
  color: var(--white);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-about-us-content {
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  text-align: left;

}

.home-about-us-content-left {

  flex-basis: 50%;
}

.home-about-us-content-right {
  flex-basis: 50%;
  justify-content: flex-start;

}

.home-about-us-content-right p {
  font-size: 1.3em;
}


.home-about-us-cards {

  margin-top: 10vh;
}

.home-about-us-card h3 {
  margin-bottom: 10px;
}

.padel-image {
  background-size: contain;
  background-image: url('../../../Asset/home-about-us-event-1.svg');
}

.football-image {
  background-size: contain;
  background-image: url('../../../Asset/home-about-us-event-2.svg');
}

.pickleball-image {
  background-size: contain;
  background-image: url('../../../Asset/home-about-us-event-3.svg');
}

.home-about-us-footer {
  /* font-size: 3.5rem; */
  margin-top: 8vh;
  line-height: 3.5rem;
}

/* Media Queries for Responsive Design */
@media (max-width: 500px) {
  .home-about-us {
    padding: 10px;
  }

  .home-about-us-content {
    flex-direction: column;
  }

  .home-about-us-buttons {
    flex-direction: column;
    gap: 10px;
  }


  .home-about-us-cards {
    margin-top: 2rem;
  }


  .home-about-us-btn {
    width: unset;
  }
}

.homeAboutUs-event-card {
  background-image: url("../../../Asset/homeAboutUs-event-card.svg");
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  .homepage-lineargradient-bg-color {
    background: linear-gradient(180deg, var(--linear-gradient-light-purple) 0%, var(--linear-gradient-dark-purple) 100%);
  }
}