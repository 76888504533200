.custom-checkbox {
  display: none;
}

/* Style for the label, to make it look like a checkbox */
.checkbox-container label {
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  background-color: var(--white);
  border: 3px solid var(--dark-blue);
  cursor: pointer;
  position: relative;
}

/* When the checkbox is checked, style the corresponding label */
.custom-checkbox:checked + label {
  background-color: var(
    --light-purple-checkbox-background
  ); /* Dark purple background when selected */
  color: var(--dark-blue); /* White check mark */
}

/* Create the check mark inside the checkbox */
.checkbox-container label:after {
  content: "✔";
  font-weight: 1000;
  font-size: 10px;
  color: var(--dark-blue);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

/* Show the check mark when the checkbox is checked */
.custom-checkbox:checked + label:after {
  display: block;
  color: var(--dark-blue); /* Check mark becomes white on selection */
}

/* For moblie device */
@media (max-width: 480px) {
  .checkbox-container label {
    width: 14px;
    height: 14px;
    border: 2px solid var(--dark-blue);
  }
}

/* For Scroll bar here*/

#scroll-container {
  scrollbar-color: auto !important;
}

#scroll-container::-webkit-scrollbar {
  width: 4px;
}

#scroll-container::-webkit-scrollbar-track {
  background: rgba(217, 217, 217, 0.2);

  border-radius: 20px;
}

#scroll-container::-webkit-scrollbar-thumb {
  background-color: var(--button-green);

  border-radius: 5px;
}

/* For accordian arrow here*/
.collapse-title::before {
  width: 2em;
  height: 2em;
  transform: rotate(180deg); /* Rotate by default */
}

/* Reverse the arrow when the accordion is expanded */
input:checked ~ .collapse-title::before {
  transform: rotate(0deg); /* Reset rotation when expanded */
}


@media screen and (max-width: 384px) {
  #right-part-checkout{
    margin-bottom: 0.3rem;
  }

  #accordian-container-checkout{
    margin-top: -1rem;
  }

  #event-title-accordian{
    font-size: 0.8rem;
   
  }
  #location-accordian, #calendar-accordian, #time-accordian{
    font-size: 0.8rem;
  }
  #location-img-accordian, #calendar-img-accordian, #time-img-accordian {
    width: 0.8rem;
  }

  #subscription-checkbox-mobile span{
    font-size: 0.76rem;
  }

  #checkout-event-price p{
    font-size: 1rem;
  }

  #checkout-event-price{
    margin-bottom: 0rem;
  }

  #agree-checkboxForm-checkout{
    margin-bottom: 0;
  }


  #proceed-to-payment-button{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
  }

  #collapse-title-accordian-checkout{
    padding-top: 0.5rem;
    padding-bottom: 0;
  }

  #collapse-content-accordian-checkout{
    padding-bottom: 0;
  }

  .checkbox-container label {
    width: 12px;
    height: 12px;
    border: 1.5px solid var(--dark-blue);
    margin-top: 0.28rem;
  }

  /* checkout form */
  #form-mainPlayer-part-checkout input, #form-loop-part-checkout input {
    padding-top:0.5rem;
    padding-bottom: 0.5rem;
  }
  #custom-select-1-checkout #custom-select-2-checkout {
    height: 0;
    
  }

  /* left side part */
  #leftSide-top-heading-checkout h2{
    font-size: 1.5rem;
  }
  #leftSide-top-sub-heading-checkout .subHeading-text{
    font-size: 0.8rem;
  }
}
