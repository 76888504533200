body{
    background-color: var(--light-gray);
}

#right-card-background {
    background-image: url('../../Asset/About/about-top-right-portion.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 2.5rem;
}

#about-bottom-content{
    background-image: url('../../Asset/About/about-bottom-content-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 2.5rem;
}
