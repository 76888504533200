.membership-package-modal .custom-checkbox {
    display: none;
  }
  
  /* Style for the label, to make it look like a checkbox */
  .membership-package-modal .checkbox-container label {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background-color: var(--white);
    border: 2px solid var(--dark-blue);
    cursor: pointer;
    position: relative;
  }
  
  /* When the checkbox is checked, style the corresponding label */
  .membership-package-modal .custom-checkbox:checked + label {
    background-color: var(--light-purple-checkbox-background); /* Dark purple background when selected */
    color: var(--dark-blue); /* White check mark */
  }
  
  /* Create the check mark inside the checkbox */
  .membership-package-modal .checkbox-container label:after {
    content: '✔';
    font-weight: 1000;
    font-size: 10px;
    color: var(--dark-blue);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
  }
  
  /* Show the check mark when the checkbox is checked */
  .membership-package-modal .custom-checkbox:checked + label:after {
    display: block;
    color: var(--dark-blue); /* Check mark becomes white on selection */
  }
  
   /* For moblie device */
   @media (max-width: 480px) {
    .membership-package-modal .checkbox-container label {
      width: 14px;
      height: 14px;
      border: 2px solid var(--dark-blue);
    }
  }