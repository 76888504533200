#eventListing-container {
    width: 100%;
    background-image: url('../../Asset/EventListing/eventlisting-bg-img.png');
    background-size: contain;
    background-position: top;
    background-repeat: no-repeat;
}

@media (max-width: 768px) {
    #eventListing-container {
        background-image: none;
        background-color: var(--background-light-gray);
    }
}